/** @type {import('houdini').ConfigFile} */
const config = {
  watchSchema: {
    url: typeof process !== 'undefined' ? process.env.PUBLIC_HYGRAPH_CONTENT_API_URL : '',
    interval: 0
  },
  plugins: {
    'houdini-svelte': {
      client: './src/lib/houdiniClient'
    }
  },
  scalars: {
    DateTime: {
      // the corresponding typescript type
      type: 'Date',
      // turn the api's response into that type
      unmarshal(val) {
        return new Date(val);
      },
      // turn the value into something the API can use
      marshal(date) {
        return date.getTime();
      }
    }
  },
  DateTime: {
    // the corresponding typescript type
    type: 'Date',
    // turn the api's response into that type
    unmarshal(val) {
      return new Date(val);
    },
    // turn the value into something the API can use
    marshal(date) {
      return date.getTime();
    }
  },
  defaultFragmentMasking: 'disable'
};

export default config;
